import {
    Box,
    Button, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid, Link, Typography,
} from "@material-ui/core";
import React from "react";
import Loading from "../../components/Loading";

const AccessDeniedDialog = ({
  openAccessDenied,
  handleAccessDeniedClose,
}) => {
    return (
        <Dialog
            titleStyle={{ textAlign: "center" }}
            maxWidth="md"
            maxHeight={"sm"}
            open={openAccessDenied}
            onClose={handleAccessDeniedClose}
            aria-labelledby="max-width-dialog-title2"
        >
            <div style={{ paddingTop: "20px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "20px",  }}>
                <div className="s20"></div>
                <DialogTitle id="max-width-dialog-title2">
                    <Typography variant="h5" align="center"
                        style={{
                            color: "#B00020"
                        }}
                    >
                        Access Denied: Business Account Required
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Container
                        style={{
                            width: "630px",
                            height: "250px",
                        }}
                    >
                        <Grid
                            container
                            style={{
                                paddingTop: "20px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                            }}
                        >
                            <Grid item>
                                <Typography
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                >
                                    It looks like you're trying to log in to the business dashboard with a general user account.<br/>
                                    To access the business dashboard, you need to have a business account.
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Here's what you can do:
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                        marginLeft: "10px",
                                    }}
                                >
                                    1. Create a Business Account
                                </Typography>
                                <Typography>
                                    <ul style={{ paddingLeft: "3em", marginTop: "0px", marginBottom: "0px", }}>
                                        <li>Sign up for a new business account using a different email address.</li>
                                    </ul>
                                </Typography>
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                        marginLeft: "10px",
                                    }}
                                >
                                    2. Use Your Current Email Address
                                </Typography>
                                <Typography style={{ marginBottom: "20px" }}>
                                    <ul style={{ paddingLeft: "3em", marginTop: "0px", marginBottom: "0px", }}>
                                        <li>If you'd like to use the same email address,
                                            please first delete your existing general account from the EcoQube app.
                                            After deleting it, you can create a new business account with that email from the dashboard.
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography>
                                    If you need assistance, please contact us at <Link href="mailto:support@ecosense.io">support@ecosense.io</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <div className="s20"></div>
                <DialogActions>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Button
                            style={{
                                background: "#3DB5E6",
                                color: "#FFFFFF",
                                width: "213px",
                                height: "36px",
                            }}
                            onClick={handleAccessDeniedClose}
                            color="primary"
                        >
                            Close
                        </Button>
                    </Box>
                </DialogActions>
                <div className="s20"></div>
            </div>
        </Dialog>
    );
};

export default AccessDeniedDialog;
