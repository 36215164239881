export const apiEndPoint = "https://api.cloud.ecosense.io/api/v1";
export const accessUrl = "https://dashboard.ecosense.io";
export const awsXApiKey = "hx5lHNle017aixo58SKyaaXc8LFBgTwm1uEGQH2Y";
export const stripeKey = "pk_live_51IZMIZI37b7asQ3bCfG593H9JPwYRFCii0ndiBDiI3uWLJCQvRPqh4cvcctMU33o73E061o988BzMziQXl3Pk2XW00OXbDF8iN";
export const awsAmplifyAuth = {
  region: 'us-west-2',
  userPoolId: 'us-west-2_vB73oNa7f',
  identityPoolId: 'us-west-2:d92683e3-c595-4f68-b4e0-8a5047ad10dd',
  userPoolWebClientId: '1dk9ul54cdo42lt6e9u1oa9g1d',
};
